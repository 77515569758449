<template>
  <div>
    <v-row class="px-0">
      <v-col cols="11" md="7">
        <div class="text-left subtitle black--text" style="margin-top: 2%">
          {{ $t("published") }}
        </div>
      </v-col>
      <v-col cols="1" md="2" class="text-right px-0">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12" md="3">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="px-3 my-1"></v-divider>
    <!-- <pre>
      {{ selected }}
    </pre> -->
    <v-row class="my-3">
      <v-col cols="12" md="6">
        <v-radio-group
          v-model="available"
          row
          hide-details
          class="mt-0"
          @change="applyFilter()"
        >
          <v-radio :label="$t('public')" :value="false" hide-details></v-radio>

          <v-radio :label="$t('private')" :value="true" hide-details></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-btn
          v-if="accessLevel(currentUser, 'publish')"
          dense
          color="primary"
          router
          to="/dashboard/entity/publish"
        >
          <v-icon left>mdi-plus</v-icon>{{ $t("new_vacancy") }}
        </v-btn>
        &nbsp;&nbsp;
        <v-btn
          :disabled="available"
          dense
          color="primary"
          @click="openShareDialog(selected,currentUser.entity.id)"
        >
          <v-icon left>mdi-share-variant</v-icon>{{ $t("share_title") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" md="12">
        <!-- <pre>{{apollo.vacancies}}</pre> -->
        <v-card v-if="$apollo.loading">
          <v-skeleton-loader
            v-bind="getVacancies"
            :type="`table-thead, table-row-divider@${getVacancies.length}, table-tfoot`"
          ></v-skeleton-loader>
        </v-card>
        <v-data-table
          v-else
          v-model="selectedVacancies"
          :headers="headers"
          :items="getVacancies"
          :search="textSearch"
          class="elevation-1"
          dense
          :no-data-text="$t('tooltip_view_vancancy')"
        >
          <template v-slot:item.title="{ item }">
            {{
              item.title
                ? item.title.length > 25
                  ? item.title.substring(0, 25) + "..."
                  : item.title.substring(0, 25)
                : ""
            }}
          </template>
          <template v-slot:item.client="{ item }">
            {{ item.client ? item.client.name : "" }}
          </template>
          <template v-slot:item.candidates="{ item }">
            <div class="text-center justify-center">
              <v-chip
                :disabled="!verifyCandidacy(item)"
                small
                color="blue"
                class="text-center"
                dark
                label
                @click="go(item.id)"
              >
                <v-icon small class="mr-2">mdi-account-multiple-outline</v-icon>
                {{ item.candidacies ? item.candidacies.length : 0 }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.ranking="{ item }">
            <div class="text-center justify-center">
              <v-chip
                :disabled="!verifyCandidacy(item)"
                small
                color="primary"
                class="text-center"
                dark
                label
                @click="goToRanking(item.id)"
              >
                <v-icon small class="mr-2">mdi-account-multiple</v-icon>
                {{
                  item.candidacies
                    ? totalCandidatesRanking(item.candidacies)
                    : 0
                }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="text-center justify-center">
              <v-chip
                small
                :color="status(item).color"
                class="text-center"
                dark
                label
                >{{
                  status(item).text != "Expirado"
                    ? status(item).text === "Publicado"
                      ? $t("published_")
                      : status(item).text
                    : $t("expired")
                }}</v-chip
              >
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="accessLevel(currentUser, 'vacancies_show_vacancy')"
              :disabled="!verifyVacancyTest(item)"
              color="blue"
              x-small
              height="30px"
              :dark="verifyVacancyTest(item)"
              link
              @click="viewVacancy(item.id)"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>

            <template>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="accessLevel(currentUser, 'vacancies_edit_vacancy')"
                    :disabled="!verifyVacancyTest(item)"
                    color="primary"
                    x-small
                    height="30px"
                    class="ma-1"
                    :dark="verifyVacancyTest(item)"
                    @click="edit(item.id)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <div v-html="$t('tooltip_edit_vancancy')"></div>
              </v-tooltip>
            </template>

            <template>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="!verifyVacancyTest(item)"
                    x-small
                    height="30px"
                    class="ma-1"
                    @click="openShareDialog(item)"
                  >
                    <v-icon small>mdi-share-variant</v-icon>
                  </v-btn>
                </template>
                <div v-html="$t('tooltip_share_vacancy')"></div>
              </v-tooltip>
            </template>
            <v-btn
              v-if="accessLevel(currentUser, 'vacancies_remove')"
              color="error"
              x-small
              height="30px"
              class="ma-1"
              dark
              @click="openConfirmeDialog(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('info_job_dialog')"
      @yes="deleteVacancy"
      @no="confirm = false"
    />
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <share-dialog />
  </div>
</template>

<script>
import { DELETE_VACANCY_MUTATION } from "../graphql/Mutation.resolver";
import { GET_VACANCIES_ENTITY_QUERY } from "../graphql/Query.resolver";
import { CANDIDATE_CANDIDACY_SUBSCRIPTION } from "./../graphql/Subscraption.resolver";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import ShareDialog from "./../components/ShareDialog.vue";
import dialogMixins from "@/mixins/dialog";
import Vacancy from "@/models/Vacancy";
import { statusVacancyMixins } from "@/mixins/status";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "Vacancy",
  mixins: [dialogMixins, statusVacancyMixins, accessLevelMixins],
  components: {
    ConfirmDialog,
    InfoViewDialog,
    ProgressDialog,
    SuccessDialog,
    ShareDialog,
  },
  data: () => ({
    available: false,
    infoDialog: false,
    selectedVacancies: [],
    // infoView: {
    //   icon: "mdi-check-circle-outline",
    //   title: "vacancy",
    //   content:
    //     "Visualize as vagas criadas, edite vagas que foram publicadas, partilhe vagas em outras plataformas, copie o link, cole na mesma vaga anunciada em outras plataformas e receba as candidaturas no WhireLab",
    // },
    shareDialog: false,
    textSearch: "",
    vacancy: new Vacancy(),
    vacancies: [],
  }),
  apollo: {
    vacancies: {
      query: GET_VACANCIES_ENTITY_QUERY,
      variables() {
        return { private: this.available };
      },
      fetchPolicy: "no-cache",
    },
    $subscribe: {
      candidateCandidacy: {
        query: CANDIDATE_CANDIDACY_SUBSCRIPTION,
        result({ data }) {
          this.updateVacancyCandidacyState(data.candidateCandidacy);
        },
      },
    },
  },
  watch: {
    vacancies: function (val) {
      this.setVacanciesState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getVacancies: "vacancy/getVacancies",
    }),
    headers() {
      return [
        {
          text: this.$t("Job_title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: this.$t("Job_client"), value: "client" },
        {
          text: this.$t("candidate"),
          align: "center",
          value: "candidates",
          width: "12%",
        },
        { text: "Ranking", align: "center", value: "ranking", width: "10%" },
        {
          text: this.$t("status"),
          align: "center",
          value: "status",
          width: "10%",
        },
        {
          text: this.$t("actions"),
          align: "left",
          value: "actions",
          sortable: false,
          width: "20%",
        },
      ];
    },
    infoView() {
      return {
        icon: "mdi-check-circle-outline",
        title: this.$t("vacancy"),
        content: this.$t("info_vacancy"),
      };
    },
    selected() {
      var vacancies = [];
      this.selectedVacancies.forEach((element) => {
        vacancies.push(element.id);
      });
      return vacancies;
    },
  },
  created(){
    this.applyFilter()
  },
  methods: {
    ...mapActions({
      removeVacancyState: "vacancy/removeVacancy",
      setVacanciesState: "vacancy/setVacancies",
      updateVacancyCandidacyState: "vacancy/updateVacancyCandidacy",
    }),
    async applyFilter() {
      await this.$apollo.queries.vacancies.refetch();
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    async deleteVacancy(id) {
      this.confirm = false;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_VACANCY_MUTATION,
          variables: {
            id,
          },
        });
        this.removeVacancyState(id);
        this.success = this.$t("vacancy_remove");
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    edit(id) {
      this.$router.push({
        path: "/dashboard/entity/publish",
        query: { id: id },
      });
    },
    viewVacancy(id) {
      this.$router.push({
        path: `/dashboard/entity/details/${id}`,
        // parameter: { id: id },
      });
    },
    go(id) {
      this.$router.push({
        path: "/dashboard/entity/candidacies",
        query: { vacancyId: id },
      });
    },
    goToRanking(id) {
      this.$router.push({
        path: "/dashboard/entity/ranking",
        query: { vacancyId: id },
      });
    },
    openShareDialog(vacancy,entityId) {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareVacancy", vacancy,entityId);
    },
    openShareDialogList(vacancies) {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareVacancyList", vacancies);
    },
    totalCandidatesRanking(candidates) {
      if (candidates) {
        return candidates.filter((c) => c.tests && c.tests.length > 0).length;
      } else {
        return 0;
      }
    },
    verifyCandidacy(vacancy) {
      return (
        vacancy.candidacies &&
        vacancy.candidacies.filter((c) => c.appliedFor).length > 0
      );
    },
    verifyVacancyTest(vacancy) {
      return vacancy.publicationDate ? true : false;
    },
  },
};
</script>
